import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IconComponent } from '@ui/icon/icon.component';
import { DialogCloseDirective } from '@directives/src/dialog-close.directive';

@Component({
	standalone: true,
	selector: 'tk-arrow-back',
	templateUrl: './arrow-back.component.html',
	styleUrls: ['./arrow-back.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [IconComponent, DialogCloseDirective],
})
export class ArrowBackComponent {}
